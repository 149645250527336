import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"

export default function Contact() {
//   const { contactPage } = props.data

  const contactPage = { id: '3884995', title: 'contacts' }
  const Component = sections['ContactDetails'] || Fallback
  return (
    // <Layout {...contactPage}>
    //   {contactPage.blocks.map((block) => {
    //     const { id, blocktype, ...componentProps } = block
    //     const Component = sections[blocktype] || Fallback
    //     return <Component key={id} {...componentProps} />
    //   })}
    // </Layout>
    <Layout {...contactPage}>
      <Component heading='Contact Details' text="let's get in touch"/>
    </Layout>
  )
}

// export const query = graphql`
//   {
//     aboutPage {
//       id
//       title
//       description
//       blocks: content {
//         id
//         blocktype
//         ...AboutHeroContent
//         ...AboutStatListContent
//         ...HomepageProductListContent
//         ...AboutLeadershipContent
//         ...HomepageBenefitListContent
//         ...AboutLogoListContent  
//         ...HomepageCtaContent
//       }
//     }
//   }
// `

